import axios from "axios";
import React, { useEffect } from "react";

function AnimeInfo(props) {
  const { data, seasons, url } = props;
  console.log(seasons);

  return (
    <div className="flex flex-col gap-4 justify-center items-center h-full">
      <div className="w-full flex flex-col gap-2 items-center">
        <img
          src="https://media.tenor.com/0cVMNSYhAksAAAAM/one-piece-monkey-d-luffy.gif"
          alt={data.name + " - poster"}
          className="w-36 rounded-full aspect-square object-contain bg-white"
        />
        <code className="py-1 px-3 bg-black text-xl">
          &lt;iframe src=&quot;{url}&quot; scrolling=&quot;0&quot;
          frameborder=&quot;0&quot; width=&quot;100%&quot;
          height=&quot;100%&quot; allowFullScreen /&gt;
        </code>
      </div>
      <h2 className="text-3xl font-semibold">{data.name}</h2>
      <div className="text-xs text-black flex gap-1">
        <span className="py-1 px-2 bg-white rounded-l-md">
          {data.stats.rating}
        </span>
        <span className="py-1 px-2 bg-[#feb9dd]">{data.stats.quality}</span>
        <span className="py-1 px-2 bg-button-primary">{data.stats.type}</span>
        <span className="py-1 px-2 bg-[#afe2ae]">
          {data.stats.episodes.sub}
        </span>
        <span className="py-1 px-2 bg-button-secondary text-white rounded-r-md">
          {data.stats.episodes.dub}
        </span>
      </div>
      {/* <p className="text-sm font-normal line-clamp-5 font-Montserrat">
        {data.description}
      </p> */}

      {/* <p className="text-sm font-normal line-clamp-5 font-Montserrat">
        {process.env.NEXT_PUBLIC_SITENAME} is the best site to watch {data.name}{" "}
        SUB online, or you can even watch {data.name} DUB in HD quality. You can
        also find all Studios anime on {process.env.NEXT_PUBLIC_SITENAME}{" "}
        website.
      </p> */}

      {seasons.length > 0 && (
        <div>
          <h3 className="text-;g font-semibold font-Montserrat my-4">
          Embed more seasons of this anime
          </h3>
          <div className="flex lg:flex-wrap gap-2 w-full overflow-x-auto px-2 hide-scroll">
            {seasons &&
              seasons.map((season, index) => (
                <a
                  href={`/watch/${season.id}`}
                  key={index}
                  className="relative flex items-center justify-center min-w-36 aspect-[20/9] rounded-lg overflow-hidden cursor-pointer  hover:scale-[1.05]  transition-all duration-300"
                >
                  <img
                    src={season.poster}
                    alt={season.title}
                    className="w-36 aspect-[20/9] object-cover rounded-lg"
                  />
                  <span className="absolute top-0 left-0 w-full h-full flex items-center bg-[rgba(0,0,0,0.6)] hover:bg-[rgba(0,0,0,0)] justify-center backdrop-blur-[2px] hover:backdrop-blur-0 font-Outfit text-sm text-center line-clamp-2 font-semibold transition-all duration-300">
                    {season.title}
                  </span>
                </a>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AnimeInfo;
