import React from "react";
import Card from "./card";

function LatestEpisodes(props) {
  const { data, title } = props;
  return (
    <div className="mb-10">
      <h2 className="text-2xl font-semibold my-4">{title}</h2>
      <div className="grid gap-5 grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6">
        {data?.map((item, index) => (
          <Card key={index} data={item} />
        ))}
      </div>
    </div>
  );
}

export default LatestEpisodes;
