"use client";
import { faClosedCaptioning, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Top10(props) {
  const { data } = props;
  const [tab, setTab] = React.useState(0);

  return (
    <div>
      {data && (<><div className="flex justify-between items-center">
        <h4 className="text-xl font-bold">Top 10</h4>
        <div className="rounded-lg overflow-hidden py-2">
          <span
            className={`py-2 px-3 ${
              tab === 0 ? "bg-[#ffbade] text-black" : "bg-button-secondary"
            }`}
            onClick={() => {
              setTab(0);
            }}
          >
            Today
          </span>
          <span
            className={`py-2 px-3 ${
              tab === 1 ? "bg-[#ffbade] text-black" : "bg-button-secondary"
            }`}
            onClick={() => {
              setTab(1);
            }}
          >
            Week
          </span>
          <span
            className={`py-2 px-3 ${
              tab === 2 ? "bg-[#ffbade] text-black" : "bg-button-secondary"
            }`}
            onClick={() => {
              setTab(2);
            }}
          >
            Month
          </span>
        </div>
      </div>
      <div className="flex flex-col bg-[#2b2a3b] pl-5">
        {tab === 0 &&
          data.today?.map((item, index) => (
            <a href={`/watch/${item.id}`} className="w-full flex gap-4 items-center" key={index}>
              <div className="flex items-center justify-center gap-1 flex-col text-2xl font-semibold">
              <span className={`${index < 3 ? "text-white" : "text-slate-500"}`}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>{" "} <hr className={`w-6 h-1 bg-white ${index < 3 ? "" : "hidden"}`}/>
              </div>
              <img
                src={item.poster}
                alt={item.name}
                className="w-[60px] aspect-[9/12] rounded-md"
              />
              <div className="py-8 w-full border-b border-button-secondary">
                <span className="line-clamp-1">{item.name}</span>
                <div className="flex items-center gap-2">
                  {item.episodes.sub && <span className="bg-[#b0e3af] text-xs py-1 px-2 text-black font-semibold"><FontAwesomeIcon icon={faClosedCaptioning} className="mr-2"/>{item.episodes.sub}</span>}
                  {item.episodes.dub && <span className="bg-[#ffbade] text-xs py-1 px-2 text-black font-semibold"><FontAwesomeIcon icon={faMicrophone} className="mr-2"/>{item.episodes.dub}</span>}
                </div>
              </div>
            </a>
          ))}
        {tab === 1 && data.week?.map((item, index) => <a href={`/watch/${item.id}`} className="w-full flex gap-4 items-center" key={index}>
              <div className="flex items-center justify-center gap-1 flex-col text-2xl font-semibold">
              <span className={`${index < 3 ? "text-white" : "text-slate-500"}`}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>{" "} <hr className={`w-6 h-1 bg-white ${index < 3 ? "" : "hidden"}`}/>
              </div>
              <img
                src={item.poster}
                alt={item.name}
                className="w-[60px] aspect-[9/12] rounded-md"
              />
              <div className="py-8 w-full border-b border-button-secondary">
                <span className="line-clamp-1">{item.name}</span>
                <div className="flex items-center gap-2">
                  {item.episodes.sub && <span className="bg-[#b0e3af] text-xs py-1 px-2 text-black font-semibold"><FontAwesomeIcon icon={faClosedCaptioning} className="mr-2"/>{item.episodes.sub}</span>}
                  {item.episodes.dub && <span className="bg-[#ffbade] text-xs py-1 px-2 text-black font-semibold"><FontAwesomeIcon icon={faMicrophone} className="mr-2"/>{item.episodes.dub}</span>}
                </div>
              </div>
            </a>)}
        {tab === 2 && data.month?.map((item, index) => <a href={`/watch/${item.id}`} className="w-full flex gap-4 items-center" key={index}>
              <div className="flex items-center justify-center gap-1 flex-col text-2xl font-semibold">
              <span className={`${index < 3 ? "text-white" : "text-slate-500"}`}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>{" "} <hr className={`w-6 h-1 bg-white ${index < 3 ? "" : "hidden"}`}/>
              </div>
              <img
                src={item.poster}
                alt={item.name}
                className="w-[60px] aspect-[9/12] rounded-md"
              />
              <div className="py-8 w-full border-b border-button-secondary">
                <span className="line-clamp-1">{item.name}</span>
                <div className="flex items-center gap-2">
                  {item.episodes.sub && <span className="bg-[#b0e3af] text-xs py-1 px-2 text-black font-semibold"><FontAwesomeIcon icon={faClosedCaptioning} className="mr-2"/>{item.episodes.sub}</span>}
                  {item.episodes.dub && <span className="bg-[#ffbade] text-xs py-1 px-2 text-black font-semibold"><FontAwesomeIcon icon={faMicrophone} className="mr-2"/>{item.episodes.dub}</span>}
                </div>
              </div>
            </a>)}
      </div>
    </>)}
    </div>
  );
}

export default Top10;
