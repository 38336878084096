"use client";
import {
  faAngleLeft,
  faAngleRight,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";

function Schedules() {
  const today = new Date();
  const containerRef = React.useRef(null);
  const [currentTime, setCurrentTime] = useState("");
  const [data, setData] = React.useState([]);
  let dat =
    today.getFullYear() +
    "-" +
    (today.getMonth() > 10
      ? today.getMonth() + 1
      : "0" + (today.getMonth() + 1)) +
    "-" +
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());
  const [date, setDate] = React.useState(dat);

  // console.log;

  // Helper function to format date as YYYY-MM-DD and get the day of the week
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" }); // Get day of the week (e.g., 'Mon')
    const monthOfYear = date.toLocaleDateString("en-US", { month: "short" }); // Get day of the week (e.g., 'Mon')
    return { formattedDate: `${year}-${month}-${day}`, dayOfWeek, monthOfYear };
  };

  // Create an array with today, the previous 7 days, and the next 3 days
  const dates = [];
  for (let i = -7; i <= 3; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() + i);
    dates.push(formatDate(currentDate));
  }

  const scroll = (direction) => {
    if (direction === "left") {
      containerRef.current.scrollLeft -= 150;
    } else if (direction === "right") {
      containerRef.current.scrollLeft += 150;
    }
  };

  useEffect(() => {
    axios
      .get(`https://aniwatch-api-opal.vercel.app/anime/schedule?date=${date}`)
      .then((response) => {
        setData(response.data.scheduledAnimes);
        console.log("data", response.data.scheduledAnimes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [date]);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const options = { timeZoneName: "short" };
      const timeString = now.toLocaleString("en-US", options);
      setCurrentTime(timeString);
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  function time() {
    var d = new Date();
    var s = d.getSeconds();
    var m = d.getMinutes();
    var h = d.getHours();
    return h + ":" + m + ":" + s;
  }

  // console.log(dates);

  return (
    <div>
      <h3 className="text-2xl font-semibold text-[#ffbade] font-Montserrat my-6 max-sm:mb-0 pb-4 flex items-center justify-between">
        <span className="max-sm:hidden">Estimated Schedules</span>{" "}
        <span className="text-base bg-[#fff] py-1 px-2 text-black rounded-full max-sm:w-full max-sm:rounded-none text-center">
          {currentTime}
        </span>
        {/* want the timer here */}
      </h3>
      <div className="relative w-full">
        <div
          className="w-full flex gap-2 overflow-x-auto hide-scroll scroll-smooth snap-x"
          ref={containerRef}
        >
          {dates.map((dateObj, index) => (
            <div
              key={index}
              className={`py-2 px-4 text-lg font-Montserrat text-center min-w-[150px] max-sm:min-w-[100px] rounded-lg snap-start cursor-pointer ${
                date === dateObj.formattedDate
                  ? "bg-[#ffbade] text-black"
                  : " bg-button-secondary"
              }`}
              onClick={() => setDate(dateObj.formattedDate)}
            >
              <span>{dateObj.dayOfWeek}</span>
              <br />
              <span
                className={`text-sm max-sm:text-xs ${
                  date === dateObj.formattedDate
                    ? "text-black"
                    : " text-slate-400"
                }`}
              >
                {dateObj.monthOfYear} {dateObj.formattedDate.split("-")[2]}
              </span>
            </div>
          ))}
        </div>
        <button
          className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-white rounded-full aspect-square w-6 text-black"
          onClick={() => scroll("left")}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button
          className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2 bg-white rounded-full aspect-square w-6 text-black"
          onClick={() => scroll("right")}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
      <div>
        {data.map((item, index) => (
          <div href={`/watch/${item.id}`} key={index} className="flex gap-5 max-sm:gap-3 max-md:gap-5 justify-between text-lg max-sm:text-sm py-4 px-2 max-sm:px-0 border-b border-button-secondary group">
            <span className="font-bold text-slate-500 flex items-center">
              {item.time}
            </span>
            <span className="w-full font-semibold line-clamp-1 max-sm:line-clamp-2">
              {item.name}
            </span>
            <span className="whitespace-nowrap py-1 px-2 text-white rounded-md text-xs min-w-[100px] flex items-center justify-center group-hover:bg-[#ffbade] group-hover:text-[#000] transition-all duration-300">
              <FontAwesomeIcon icon={faPlay} className="mr-2" /> Episode{" "}
              {item.episode}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Schedules;
