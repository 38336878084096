"use client";
import { useEffect, useState } from "react";
import {
  BellIcon,
  DiscIcon,
  MenuIcon,
  RssIcon,
  TextIcon,
  TwitterIcon,
} from "./icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        const navbar = document.querySelector("header");
        if (window.scrollY > 0) {
          navbar.classList.add(
            "backdrop-blur-sm",
            "shadow-lg",
            "bg-[rgba(0,0,0,0.5)]"
          );
          // navbar.classList.remove('fixed');
        } else {
          navbar.classList.remove(
            "backdrop-blur-sm",
            "shadow-lg",
            "bg-[rgba(0,0,0,0.5)]"
          );
          // navbar.classList.add('fixed');
        }
      };
    }
  }, []);

  return (
    <header className="mb-2">
      <div className="flex items-center justify-between p-4 bg-gradient-to-b from-background-primary border-b border-button-secondary to-transparent transition-all duration-300 sticky top-0 left-0 right-0 z-[999] min-h-[70px] max-h-[70px]">
        <div className="flex items-center space-x-4">
          {/* <MenuIcon className="w-6 h-6" /> */}
          <h1 className="text-2xl font-bold">
            <a href="/">Anime-Embeds</a>
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <input
            type="text"
            placeholder="Search anime..."
            className="px-4 py-2 max-w-[300px] w-[400px] bg-gray-800 rounded-md outline-none max-md:hidden"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                window.location.href = `/search/${e.target.value}`;
              }
            }}
          />
          {/* <button className="text-white max-md:hidden">Filter</button> */}
          {/* <button className="text-white">Join now</button>
        <div className="flex items-center space-x-2">
          <DiscIcon className="w-6 h-6" />
          <TextIcon className="w-6 h-6" />
          <RssIcon className="w-6 h-6" />
          <TwitterIcon className="w-6 h-6" />
          <BellIcon className="w-6 h-6" />
        </div> */}
          {/* <button className="text-white bg-slate-700 px-2 py-1 min-w-10">EN</button>
        <button className="text-white bg-slate-700 px-2 py-1 min-w-10">JP</button> */}
          <div className="gap-2 hidden max-md:flex">
            <button
              className={`text-white bg-slate-700 px-2 py-1 min-w-8 rounded-full ${
                isSearchOpen ? "hidden" : "block"
              }`}
              onClick={() => setIsSearchOpen(!isSearchOpen)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <button
              className={`text-white bg-slate-700 px-2 py-1 min-w-8 rounded-full ${
                isSearchOpen ? "block" : "hidden"
              }`}
              onClick={() => setIsSearchOpen(!isSearchOpen)}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`items-center space-x-4 m-auto w-fit px-4 py-2 pr-2 bg-gray-800 rounded-md mb-2 ${
          isSearchOpen ? "flex" : "hidden"
        }`}
      >
        <input
          type="text"
          id="query"
          placeholder="Search anime..."
          className="bg-transparent outline-none"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              window.location.href = `/search/${e.target.value}`;
            }
          }}
        />
        <button className="text-white bg-slate-700 px-2 py-1 min-w-8 rounded-md" onClick={() => window.location.href = `/search/${document.getElementById("query").value}`}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </header>
  );
}

export default Navbar;
