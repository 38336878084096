"use client";
import React, { useEffect, useState, useRef } from "react";
// import "plyr/dist/plyr.css";
import Hls from "hls.js";
import axios from "axios";
// import dynamic from "next/dynamic";
import AnimeInfo from "./components/watch/animeInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import {
  faClosedCaptioning,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import LatestEpisodes from "./components/latestEpisodes";
import Top10 from "./components/top10";

const BaseUrl = process.env.REACT_APP_BASE_URL;

// const Plyr = dynamic(() => import("plyr"), { ssr: false });

function Page() {
  const params = useParams();
  const id = params.animeId;
  console.log(id);
  console.log(BaseUrl);
  const [episodes, setEpisodes] = useState([]);
  const [servers, setServers] = useState([]);
  const [epId, setEpId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const playerRef = useRef(null); // Ref to store the Plyr instance
  const [animeInfo, setAnimeInfo] = useState([]);
  const [slug, setSlug] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${BaseUrl}/anime/episodes/${id}`).then((response) => {
      setEpisodes(response.data.episodes);
    });

    axios.get(`${BaseUrl}/anime/info?id=${id}`).then((response) => {
      const info = {
        name: response.data.anime.info.name,
        id: response.data.anime.info.id,
        poster: response.data.anime.info.poster,
      };

      setAnimeInfo(response.data);
      // console.log("infoodsaffffffff", response.data);
      const oldhistory = JSON.parse(localStorage.getItem("history")) || [];

      // Check if the id already exists in the array
      const exists = oldhistory.some((item) => item.id === info.id);

      if (!exists) {
        const newhistory = [...oldhistory, info];
        localStorage.setItem("history", JSON.stringify(newhistory));
        console.log("infoo", newhistory);
      } else {
        console.log("Info with this ID already exists.");
      }
    });

    axios.get(`${BaseUrl}/anime/home`).then((response) => {
      setData(response.data);
    });
  }, []);

  useEffect(() => {
    if (episodes.length > 0) {
      getServers(episodes[0].episodeId);
    }
  }, [episodes]);

  useEffect(() => {
    if (servers) {
      console.log("in tje use effect");
      getSrc("hd-1", "sub", epId);
    }
    console.log("hd-1", "sub", epId);
  }, [servers]);

  const getServers = (epId) => {
    console.log(epId);
    setEpId(epId);
    axios
      .get(`${BaseUrl}/anime/servers?episodeId=${epId}`)
      .then((response) => {
        setServers(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSrc = (serverId, type, epId) => {
    axios
      .get(
        `https://e.moview.co.in/shorten?url=${encodeURIComponent(
          `https://moview.co.in/watch?id=${epId}&server=${serverId}&category=${type}`
        )}`
      )
      .then((response) => {
        // setPlayerData(response.data);
        setSlug(response.data.shortened_url);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <main className="w-full m-auto">
      <div className="w-full relative overflow-hidden">
        <img
          src={animeInfo.anime?.info.poster}
          className="absolute top-0 left-0 right-0 bottom-0 w-full"
          alt={animeInfo.anime?.info.name}
        />
        <div className="playerContainer px-10 bg-[rgba(24,41,58,0.686)] max-md:px-2 py-5 w-full backdrop-blur-lg flex max-lg:flex-col justify-between text-lg overflow-auto relative">
          {/* <div className="w-[20%] episodelist absolute top-0 left-0 right-0 bottom-0 overflow-y-auto">
          <p className="py-2 px-2 bg-black w-full">List of Episodes</p>
          {episodes.map((episode, index) => (
            <div
              key={index}
              className={`flex font-light py-2 pr-2 cursor-pointer ${
                index % 2 === 0 ? "bg-slate-800" : ""
              }`}
              onClick={() => getServers(episode.episodeId)}
            >
              <span className="px-5">{index + 1}</span>
              <span className="line-clamp-1">{episode.title}</span>
            </div>
          ))}
        </div> */}
          <div className="w-[60%] max-lg:w-full bg-background-primary">
            <div className="w-full aspect-video bg-black bg-[url('https://cdn.dribbble.com/users/278172/screenshots/2569250/checkmate-dribbble.gif')] bg-no-repeat bg-center">
              <iframe
                allowFullScreen
                src={`https://moview.co.in/e/${slug}`}
                className={`min-w-full aspect-video ${
                  isLoading ? "hidden" : "block"
                }`}
                crossOrigin="anonymous"
              ></iframe>
            </div>
            <div className="text-base">
              <div className="sub flex gap-3 mt-2 items-center">
                <span className="px-3 py-1 bg-blue-400 max-sm:px-2x sm:min-w-20 max-w-20">
                  <FontAwesomeIcon
                    icon={faClosedCaptioning}
                    className="mr-2 max-sm:mr-0 max-sm:min-w-5"
                  />
                  <span className="max-sm:hidden">Sub</span>
                </span>
                {servers.sub?.map((server, index) => (
                  <div key={index}>
                    <p
                      className="py-2 bg-black rounded-md px-3 w-full cursor-pointer"
                      onClick={() => getSrc(server.serverName, "sub", epId)}
                    >
                      {server.serverName}
                    </p>
                  </div>
                ))}
              </div>
              <div className="sub flex gap-3 mt-2 items-center">
                <span className="px-3 py-1 bg-blue-400 max-sm:px-2x  sm:min-w-20 max-w-20">
                  <FontAwesomeIcon
                    icon={faMicrophone}
                    className="mr-2 max-sm:mr-0 max-sm:min-w-5"
                  />
                  <span className="max-sm:hidden">Dub</span>
                </span>
                {servers.dub?.map((server, index) => (
                  <div key={index}>
                    <p
                      className="py-2 bg-black rounded-md px-3 w-full cursor-pointer"
                      onClick={() => getSrc(server.serverName, "dub", epId)}
                    >
                      {server.serverName}
                    </p>
                  </div>
                ))}
              </div>
              <p className="py-2 px-2 bg-black w-full my-2">List of Episodes</p>
              <div className="relative">
                <div className="absolute left-0 right-0 bottom-0 h-8 bg-gradient-to-t from-black to-transparent"></div>
                <div className="flex gap-2 w-full flex-wrap min-h-fit pb-8 max-h-48 overflow-y-auto hide-scroll">
                  {episodes.map((episode, index) => (
                    <div
                      key={index}
                      className={`flex font-light  cursor-pointer w-fit aspect-square items-center justify-center ${
                        index % 2 === 0 ? "bg-slate-800" : "bg-slate-600"
                      }`}
                      onClick={() => getServers(episode.episodeId)}
                    >
                      <span
                        className="w-9 aspect-square flex items-center justify-center"
                        title={episode.title}
                      >
                        {index + 1}
                      </span>
                      {/* <span className="line-clamp-1">{episode.title}</span> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[38%] max-lg:w-full max-lg:max-w-[600px] max-lg:m-auto max-lg:my-8 max-lg:bg-button-secondary max-lg:p-5 rounded-lg">
            {animeInfo.anime?.info && (
              <AnimeInfo
                data={animeInfo.anime.info}
                seasons={animeInfo.seasons}
                url = {`https://moview.co.in/e/${slug}`}
              />
            )}
          </div>
        </div>
      </div>
      <div className="sharethis-inline-share-buttons my-4"></div>
      <div  className="px-16 max-md:px-3 my-7 flex justify-between max-lg:flex-col">
        <div className="w-[73%] max-lg:w-full">
          {animeInfo.recommendedAnimes && (
            <LatestEpisodes
              data={animeInfo.recommendedAnimes}
              title="Recommended for you"
            />
          )}
        </div>
        <div className="w-[25%] max-lg:w-full">
          {data.top10Animes && <Top10 data={data.top10Animes} />}
        </div>
      </div>
    </main>
  );
}

export default Page;
