import React from 'react'

function Card(props) {

    const {name, poster, id, type, duration} = props.data
    // console.log("props",props)

  return (
    <a href={`/watch/${id}`}>
        <div className='w-full'>
      <div className='relative w-full aspect-[9/12]'>
      <img src={poster} alt="" className='w-full h-full object-cover'/>
      <div className='absolute top-[80%] left-0 right-0 bottom-0 bg-gradient-to-t from-background-primary to-transparent'></div>
      </div>
      <h3 className='text-md line-clamp-1'>{name}</h3>
      <span className='font-Montserrat font-light text-slate-400 text-sm'>{type.split(" ")[0]} &nbsp; &#8226; &nbsp; {duration}</span>
    </div>
    </a>
  )
}

export default Card
