import axios from 'axios';
import { useEffect, useState } from 'react';
import LatestEpisodes from './components/latestEpisodes'
import Schedules from './components/schedules'
import Top10 from './components/top10'
import Navbar from './components/navbar';
import { Helmet } from 'react-helmet';

const baseURL = process.env.BASE_URL;
export const revalidate = 0;

// export const getHome = async () => {
//     const response = await axios.get(`${baseURL}/anime/home`);
//     return await response.data;
// }

// export const getSearchResults = async (query) => {
//     const response = await axios.get(`${baseURL}/anime/search?q=${query}`);
//     return await response.data;
// }


function App() {

  const[data, setData] = useState([]);

  useEffect(() => {
    axios.get(`https://aniwatch-api-opal.vercel.app/anime/home`)
      .then(response => {
        setData(response.data);
        console.log(response.data);
      });
  }, []);

  return (
    <div className="App bg-background-primary">
      <Helmet>
                <title>AnimeEmbeds | Home</title>
                <meta property="og:title" content="Anime Embeds" />
    <meta property="og:description" content="Embedding made Easy with AnimeEmbeds" />
    <meta property="og:image" content="https://hianime.to/images/anw-min.webp?v=0.1" />
    <meta property="og:url" content="https://moview.site" />
            </Helmet>
      <div className="px-16 max-md:px-3 flex justify-between max-lg:flex-col">
        <div className="w-[73%] max-lg:w-full">
          {data && <LatestEpisodes data={data.latestEpisodeAnimes} title="Latest Episodes" />}
          <Schedules />
          {/* <LatestEpisodes data={data.topUpcomingAnimes} title="Top Upcoming" /> */}
        </div>
        <div className="w-[25%] max-lg:w-full">
          <Top10 data={data.top10Animes} />
          {/* <Genres data={data.genres}/> */}
        </div>
      </div>
    </div>
  );
}

export default App;
