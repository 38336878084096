import axios from 'axios'
import LatestEpisodes from './latestEpisodes'
import Top10 from './top10'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
const baseURL = process.env.REACT_APP_BASE_URL;

function Search({}) {

    const { keyword } = useParams()
    const query = keyword
    const [data , setData] = useState([])
    const [homeData , setHomeData] = useState([])

    useEffect(() => {
        axios.get(`${baseURL}/anime/search?q=${query}`)
            .then((response) => {
                setData(response.data)
                console.log(response.data)
                // console.log((`${baseURL}/anime/search?q=${query}`))
            })
            .catch((error) => {
                console.log(error)
            })

        axios.get(`${baseURL}/anime/home`)
            .then((response) => {
                setHomeData(response.data)
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

  return (
    <main className=''>
        <div className="px-16 max-md:px-3 my-7 flex justify-between max-lg:flex-col">
        <div className="w-[73%] max-lg:w-full">
          <LatestEpisodes data={data.animes} title={`Search results for "${query}"`} />
        </div>
        <div className="w-[25%] max-lg:w-full">
          <Top10 data={homeData.top10Animes} />
          {/* <Genres data={homeData.genres}/> */}
        </div>
      </div>
    </main>
  )
}

export default Search
